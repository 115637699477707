import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, CardGroup, ListGroup, ListGroupItem, CardDeck} from 'react-bootstrap'

function MechanicalPage(props){

  return(
    <>
  <Card.Title className=" text-center engineeringHeader white-text">Mechanical Engineering</Card.Title>
 
<Row className="container-fluid center-block">
<div className="col-md-2"> </div>
<div className="col-md-8">
<CardDeck className="bottomP">
  <Card className="boxShadow">


    <Card.Img variant="top" src={"./" + "mechanical1.png"}/>
    <Card.Header className="headerColor white-text text-center"><Card.Title>Mechanical Systems</Card.Title></Card.Header>
    <Card.Body>
      
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Air Conditioner/Refrigeration Systems Sizing and Installation</ListGroupItem>
    <ListGroupItem>Air Conditioner and Refrigeration Systems Corrective and Preventative Maintenance</ListGroupItem>
    <ListGroupItem>Pump Sizing and Installation</ListGroupItem>
    <ListGroupItem>Installation of Machinery</ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>

 
  <Card.Body>
    
  </Card.Body>
  
  </Card>


  <Card  className="boxShadow">
    <Card.Img variant="top" src={"./" + "mechanical2.png"} />
    <Card.Header className="headerColor white-text text-center"><Card.Title>Mechanical Designs</Card.Title></Card.Header>
    <Card.Body >
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Mechanical Designs and Technical AutoCAD Drawings</ListGroupItem>
    <ListGroupItem>Mechanical 3D Computer Software Renders</ListGroupItem>
    <ListGroupItem>3D PLA Models Design and Printing</ListGroupItem>
    <ListGroupItem>Welding and Fabrication</ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>
  
  <Card.Body>
   
   </Card.Body>
 
  </Card>



</CardDeck>
    </div>

    <div className="col-md-2"> </div>
    
    </Row>
  
 
    </>
  )
}

export default MechanicalPage;
