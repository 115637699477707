import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, CardGroup, ListGroup, ListGroupItem, CardDeck} from 'react-bootstrap'

function ComputerPage(props){

  return(
    <>
   <Card.Title className=" text-center engineeringHeader white-text">Computer Systems Engineering</Card.Title>
 
<Row className="container-fluid center-block">

<div className="col-md-12">
<CardDeck className="bottomP">
  <Card  className="boxShadow">


    <Card.Img variant="top" src={"./" + "computer7.png"}/>
    <Card.Header className="headerColor white-text text-center"><Card.Title>Software Development & Management</Card.Title></Card.Header>
    <Card.Body>
      
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Mobile Development: iOS and Android Development</ListGroupItem>
    <ListGroupItem>App Store and Play Store Deployment and Management</ListGroupItem>
    <ListGroupItem>Web Development: Static & Dynamic Websites, Web Apps and Hosting Management</ListGroupItem>
    <ListGroupItem>E-Commerce Development, Messasing Applications, Program Automation  </ListGroupItem>
    <ListGroupItem>Domain Name Acquisitions</ListGroupItem>
    <ListGroupItem>API Development: REST, GraphQL</ListGroupItem>
    <ListGroupItem>Cloud Development and Dev Ops Cloud Hosting Management: AWS, AZURE, Google Cloud, Oracle Cloud</ListGroupItem>
    <ListGroupItem>Database Development and Management : SQL and NoSQL </ListGroupItem>
     <ListGroupItem>Custom Software Development: C++, Java, Swift, Javascript, ReactJS, Node.js, Django, PHP, Python</ListGroupItem>
    
  </ListGroup>
      </Card.Text>
    </Card.Body>

 
  <Card.Body>
    
  </Card.Body>
    
  </Card > 


  <Card  className="boxShadow">
    <Card.Img variant="top" src={"./" + "computer6.png"} />
    <Card.Header className="headerColor white-text text-center"><Card.Title>Hardware Development & Prototyping</Card.Title></Card.Header>
    <Card.Body>
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Rasberry Pi & Arduino Systems Development</ListGroupItem>
    <ListGroupItem>Microcontroller Programming</ListGroupItem>
    <ListGroupItem>Internet of Things (IoT) Development and Prototyping</ListGroupItem>
    <ListGroupItem>Custom Product Design</ListGroupItem>
    <ListGroupItem>Custom Product Prototyping</ListGroupItem>
    <ListGroupItem>Electronic Component Soldering and Desoldering</ListGroupItem>
  
  </ListGroup>
      </Card.Text>
    </Card.Body>
 
  <Card.Body>
   
   </Card.Body>
  
  </Card>


  <Card  className="boxShadow">
    <Card.Img variant="top" src={"./" + "computer3.png"} />
    <Card.Header className="headerColor white-text text-center"><Card.Title>Data Analysis & Visualization</Card.Title></Card.Header>
    <Card.Body>
      <Card.Text>
      <ListGroup className="list-group-flush">
      <ListGroupItem>Real Time Data Monitoring</ListGroupItem>
    <ListGroupItem>Big Data Analytics</ListGroupItem>
    <ListGroupItem>R Data Analytics</ListGroupItem>
    <ListGroupItem>Data Compilation and Visualization</ListGroupItem>
    <ListGroupItem>Improvement Analaysis with Action Plan</ListGroupItem>
    <ListGroupItem>Data Insights Development</ListGroupItem>
    <ListGroupItem>Agriculture Sensors Data Visualization: Soil Moisture, Temperature and Humidity Data</ListGroupItem>
    <ListGroupItem></ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>
 
  <Card.Body>
   
  </Card.Body>
  
  </Card>
</CardDeck>
    </div>


    
    </Row>
  
 
    </>
  )
}

export default ComputerPage;
