import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, CardGroup, ListGroup, ListGroupItem, CardDeck} from 'react-bootstrap'
import Projects from './pcarousel.js'
function CivilPage(props){

  return(
    <>
  
    <Card.Title className=" text-center engineeringHeader white-text">Civil Engineering</Card.Title>
 


<Row className="container-fluid center-block ">

<div className="col-md-12">
<CardDeck className="bottomP">
  <Card  className="boxShadow">


    <Card.Img variant="top" src={"./" + "civil3.png"}/>
    <Card.Header className="headerColor white-text text-center"><Card.Title>Auditing and Supervision of Construction</Card.Title></Card.Header>
    <Card.Body >
      
      <Card.Text>
      <ListGroup className="list-group-flush ">
    <ListGroupItem >Design revision and correction where necessary</ListGroupItem>
    <ListGroupItem>Site visits and generation of weekly progress reports</ListGroupItem>
    <ListGroupItem>Design Optimization</ListGroupItem>
    <ListGroupItem>Cost Recovery Recommendations</ListGroupItem>
    <ListGroupItem>Supervisory Project Management</ListGroupItem>
    <ListGroupItem>Cost Prevention prior to contract exectution</ListGroupItem>
    <ListGroupItem>Risk Assessments</ListGroupItem>
    <ListGroupItem>Feasibility Studies</ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>

  
  <Card.Body>
    
  </Card.Body>
 
  </Card>


  <Card  className="boxShadow">
    <Card.Img variant="top" src={"./" + "civil2.png"} />
    <Card.Header className="headerColor white-text text-center"><Card.Title>General Construction</Card.Title></Card.Header>
    <Card.Body>
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Commercial Buildings</ListGroupItem>
    <ListGroupItem>Residential Buildings</ListGroupItem>
    <ListGroupItem>Industrial Structures</ListGroupItem>
  </ListGroup>
      </Card.Text>
    </Card.Body>
   
  <Card.Body>
   
   </Card.Body>
 
  </Card>


  <Card  className="boxShadow">
    <Card.Img variant="top" src={"./" + "civil1.png"} />
    <Card.Header className="headerColor white-text text-center"><Card.Title>Design</Card.Title></Card.Header>
    <Card.Body>
      <Card.Text>
      <ListGroup className="list-group-flush">
    <ListGroupItem>Structural Calculations and Autocad Designs and Drawings</ListGroupItem>
    <ListGroupItem>Drainage Design[Road, Commercial, and Residential]</ListGroupItem>
    <ListGroupItem>Building Retrofitting</ListGroupItem>
    <ListGroupItem>Structural Integrity Reports, Tests and Analyses</ListGroupItem>

  </ListGroup>
      </Card.Text>
    </Card.Body>
   
  <Card.Body>
   
  </Card.Body>
 
  </Card>
</CardDeck>
    </div>


    
    </Row>
  
 
    </>
  )
}

export default CivilPage;
