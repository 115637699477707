import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import {Row, Col} from 'react-bootstrap'
function AboutPage(props){

  return(
    <>
    <Row><br></br></Row>
    <div className=" aboutUsContainer">
    <div className="row container-fluid">
    
    <div className="col-lg-3 lPadding">



 <div className="row ">
 <div className="articleHeaderContainer ">
    <Card.Title className="boxShadow articleHeader white-text">About Us</Card.Title>
</div>
</div>


<Card  style={{ height: '45em' }}  className="boxShadow">
    <Card.Body className="tertiaryColor white-text aboutUsBox">
      <Card.Text className="">
Belize Engineering Services and Supplies comprises a team of engineers, procurement specialists and technicians brought together by their love for Belize and call to action. Pooling education, knowledge, experience and skills to provide the best services to ignite change is what yielded Belize Engineering Services and Supplies. Our goal is to break through confines and make first class infrastructure and development possible in Belize.  
      </Card.Text>
    </Card.Body>
  </Card>
  <br />



    </div>


    <div className="col-lg-6  center-block text-center"> 
      <div className="dmdContainer">
    <div class="DiamondContainer">
      <div class="dmd dmd1">
          <div>
            <a href="#" class="yellow tile-link text-center">Efficiency</a>
          </div>
        </div>
        
        <div class="dmd dmd2">
          <div>
            <a href="#" class="yellow tile-link">Engineering</a>
          </div>
        </div>
        
        <div class="dmd dmd3">
          <div>
            <a href="#" class="yellow tile-link">Safety</a>
          </div>
        </div>
        
        <div class="dmd dmd4">
          <div>
            <a href="#" class="yellow tile-link">Profitability</a>
          </div>
        </div>
    </div>

    </div>

    </div>

    <div className="col-lg-3 lPadding">

 <div className="row ">
 <div className="articleHeaderContainer ">
    <Card.Title className="boxShadow articleHeader white-text">Mission</Card.Title>
</div>
</div>

<Card  style={{ height: '20em' }}  className="boxShadow">
    <Card.Body className="tertiaryColor white-text aboutUsBox">
      <Card.Text className="">
        To develop and uplift industries and infrastructure in Belize through world-class engineering techniques and practices.
      </Card.Text>
    </Card.Body>
  </Card>
  <br />

 <div className="row ">
 <div className="articleHeaderContainer ">
    <Card.Title className="boxShadow articleHeader white-text">Vision</Card.Title>
</div>
</div>
<Card style={{ height: '20em' }}  className="boxShadow">
    <Card.Body className="tertiaryColor white-text aboutUsBox">
      <Card.Text className="">
        To bring prosperity to Belize through quality, effective and efficient engineering.
      </Card.Text>
    </Card.Body>
  </Card>
  <br />



  


 

    </div>

  

    </div>
    </div>


          

    </>
  )
}

export default AboutPage;
