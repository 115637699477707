import Jumbotron from 'react-bootstrap/Jumbotron'
import Carousel from 'react-bootstrap/Carousel'
import CarouselInfinite from './Carousel.js' 
import {Row, Col, Card, Button} from 'react-bootstrap'
import Projects from './pcarousel.js'
function HomePage(props){

  return(
    <>
    
          
   <Carousel>
  <Carousel.Item>
    <img
      className="cover w-100"
      src={"./" + 'civil.png'}
      alt="First slide"
    />
    <div id="image1">
<Carousel.Caption>
      <h3 className="carousel-text">Building the Future</h3>

       <em className="carousel-second-text">Procured and Engineered for efficiency, safety and profitability of our clients</em> 
    </Carousel.Caption>


    </div>
      </Carousel.Item>
  <Carousel.Item>
    <img
      className="cover w-100"
      src={"./" + 'mechanical.png'}
      alt="Second slide"
      
    />
    <div id="image1">
    <Carousel.Caption>
      <h3 className="carousel-text">Second slide label</h3>
      <em className="carousel-second-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</em>
    </Carousel.Caption>
    </div>
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="cover w-100"
      src={"./" + 'electrical.png'}
      alt="Third slide"
    />
<div id="image1">
    <Carousel.Caption>
      <h3 className="carousel-text">Third slide label</h3>
      <em className="carousel-second-text">Praesent commodo cursus magna, vel scelerisque nisl consectetur.</em>
    </Carousel.Caption>
    </div>
  </Carousel.Item>
</Carousel>
 <div className="text-center">
    <em className="catch">Procured and Engineered for efficiency, safety and profitability of our clients</em> 
</div>


    <div className="hiddenSlider  row  container-fluid">
 

 <div className="col-md-1"></div>

 

    <div className="col-md-10 center-block text-center  container-fluid"> 
 <Projects className="text-center"> </Projects>

    </div>

   

   
  <div className="col-md-1"></div>


    </div>

<div className=" homeStrip center-block row text-white text-center">
  <div className="col-md-1"></div>

    <div className="col-md-2"> 
   <a className=" homeCircleColor" href ="/mechanical">
    <div className="homeCircle primaryColor">Mechanical Engineering </div>
    </a>
    </div>

    <div className="col-md-2"> 
   <a className=" homeCircleColor" href ="/electrical">
    <div className="homeCircle secondaryColor">Electrical Engineering </div>
    </a>
    </div>


    <div className="col-md-2"> 
   <a className=" homeCircleColor" href ="/civil">
    <div className="homeCircle primaryColor">Civil Engineering </div>
    </a>
    </div>

    <div className="col-md-2"> 
   <a className=" homeCircleColor" href ="/computer">
    <div className="homeCircle secondaryColor">Computer Systems Engineering </div>
    </a>
    </div>


    <div className="col-md-2"> 
   <a className=" homeCircleColor" href ="/supplies">
    <div className="homeCircle primaryColor">Supplies</div>
    </a>
    </div>


   
  <div className="col-md-1"></div>

    </div>






    </>
  )
}

export default HomePage;
