import React from 'react'
import {Nav, Navbar, NavbarBrand, Container, Button, Row, Col} from 'react-bootstrap'

export const Footer = props => {

  return (
    <>


   <footer>  
    <div className="container text-center">
<Row>
    <Col>
    
 <span variant="outline-light"> <u>Engineering</u></span>
    <br></br>
    <a  className="footerLightLink white-text" href="/civil">Civil Engineering</a>
    <br></br>
    <a  className="footerLightLink white-text" href="/mechanical">Mechanical Engineering</a>
    <br></br>
  
    <a  className="footerLightLink white-text" href="/computer">Computer Systems Engineering</a>
    <br></br>
    <a  className="footerLightLink white-text" href="/electrical">Electrical Engineering</a>
    </Col>
    <Col>
    <a  className="footerLightLink white-text" href="/">
   <img 
    src={"./" + 'bess.png'}
    width='128px'
    height='128px'
    /> 
    </a>
    </Col>
    <Col>
 <a className="white-text footerLink" href={"callto:" + "+5016746664"}> <i class="fas fa-phone-alt"></i> (+501) 674-6664</a>
<br></br>
      <a className="white-text footerLink" href={"mailto:" + "engineering@bess.bz"}> <i class="fas fa-envelope"></i> engineering@bess.bz</a>

   
 

    </Col>
  </Row>
    <hr className="hrWhite"></hr>
              <span> Copyright 2022 BESS | Privacy Policy | Terms of Use</span> 
    </div>
            
        </footer>




    </>
  )

}
