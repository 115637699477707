import React from 'react'
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';




const handleDragStart = (e) => e.preventDefault();

const Project = (props) =>{
return (



		  <a className=" homeCircleColor" href ="/mechanical">
    <div className="homeCircle primaryColori text-center">Mechanical Engineering </div>
    </a>
		)

}
const items = [
    <div className="circleSlider ">
	   <a className=" homeCircleColor" href ="/mechanical">
    <div className="homeCircle primaryColor" onDragStart={handleDragStart}>Mechanical Engineering </div>
    </a>
  </div>,
 <div className="circleSlider">
	   <a className=" homeCircleColor" href ="/electrical">
    <div className="homeCircle secondaryColor" onDragStart={handleDragStart}>Electrical Engineering </div>
    </a>
  </div>,
 <div className="circleSlider">
	   <a className=" homeCircleColor" href ="/civil">
    <div className="homeCircle primaryColor" onDragStart={handleDragStart}>Civil Engineering </div>
    </a>
  </div>,
 <div className="circleSlider">
	   <a className=" homeCircleColor" href ="/computer">
    <div className="homeCircle secondaryColor" onDragStart={handleDragStart}>Computer Systems Engineering </div>
    </a>
  </div>,
 <div className="circleSlider">
	   <a className=" homeCircleColor" href ="/supplies">
    <div className="homeCircle primaryColor" onDragStart={handleDragStart}>Supplies </div>
    </a>
  </div>
];

const Projects = () => {

  return(
   <AliceCarousel autoPlayInterval="1000"   autoPlayStrategy="default" infinite="true" autoPlay="true" disableButtonsControls="true" disableDotsControls="true" mouseTracking items={items} />

  )
}


export default Projects;
