  import React from 'react'
import {Nav, Navbar, NavDropdown, Form, FormControl, Button} from "react-bootstrap"
import {
  Link
} from "react-router-dom"

export const NavBar = props => {
  return (
    <>
<Navbar bg="light" expand="md" variant="light" >
  <Navbar.Brand href="/"> 
    <img 
    src={"./" + 'besslogo.png'}
    width='100px'

    /> 
</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav">
    <Nav defaultActiveKey="/home" className="mr-auto">
      <Nav.Link className=""href="/">Home</Nav.Link>
      <Nav.Link href="about">About Us</Nav.Link>
      <NavDropdown title="Services" id="basic-nav-dropdown">
        <NavDropdown.Item  className="navDropColor" href="/civil">Civil Engineering</NavDropdown.Item>
        <NavDropdown.Item className="navDropColor" href="/mechanical"> Mechanical Engineering</NavDropdown.Item>
         <NavDropdown.Item className="navDropColor" href="/electrical"> Electrical Engineering</NavDropdown.Item>
        <NavDropdown.Item className="navDropColor" href="/computer">Computer and Infomation Systems Development</NavDropdown.Item>

        <NavDropdown.Divider />
        <NavDropdown.Item className="navDropColor" href="/supplies"> Supplies</NavDropdown.Item>
      </NavDropdown>
     <Nav.Link href="/contact">Contact Us</Nav.Link>
    </Nav>
    <Form inline>
    
    <div>&nbsp;</div>
    <div>&nbsp;</div>
    <a href={"callto:" + "+5016746664"}> <i class="fas fa-phone-alt"></i> (+501) 674-6664</a>
    <div>&nbsp;</div>
      <Button variant="link">
      <a href={"mailto:" + "engineering@bess.bz"}> <i class="fas fa-envelope"></i> engineering@bess.bz</a>
      </Button>
    </Form>
  </Navbar.Collapse>
</Navbar>
    </>
  )
}

//<Button variant="outline-primary"> <i class="fas fa-shopping-cart"></i> e-Store</Button>
//   <NavDropdown.Item><Link className="navDropColor" to="/civil">civil</Link></NavDropdown.Item> 